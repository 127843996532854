<template>
    <div align="center">
        <br>
        <div align="center">
            <span class="text-h4">Annulla Quotazioni</span>
            <br>

        </div>
        <br><br>

        <div class="row justify-center">

            <div class="col-md-4 col-12 q-pr-md" align="left">
                <q-list  separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Area:</q-item-label>
                            <q-item-label caption>{{quotazione.area}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Prodotto:</q-item-label>
                            <q-item-label caption>{{quotazione.id_prodotto}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.guid_preventivo}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Descrizione preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.nominativo}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della quotazione:</q-item-label>
                            <q-item-label caption>{{getStato}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Unit&agrave; Operativa:</q-item-label>
                            <q-item-label caption>{{quotazione.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Operatore:</q-item-label>
                            <q-item-label caption>{{quotazione.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>

            <div class="col-md-6 col-12 q-pr-md">
                <h5 class="q-mt-sm">Inserisci il motivo dell'annullo</h5>

                <q-input
                    outlined
                    square
                    v-model="motivo_annullo"
                    filled
                    type="textarea"
                />
                <br>
            </div>

            <div class="col-12 col-md-12">
                <hr>
            </div>

            <div class="row col-12 col-md-12 justify-center">

                <div class="col-12 col-md-6" align="center">
                    <QQButton label="TORNA AL MENU"
                        color="blue-grey"
                        icon="undo"
                        size="md"
                        icon_align="left"
                        @click.native="onIndietroClicked()"
                    />
                </div>

                <div class="col-12 col-md-6" align="center">
                    <QQButton label="ANNULLA QUOTAZIONE"
                        color="blue-grey"
                        icon="mdi-email-edit-outline"
                        size="md"
                        icon_align="right"
                        @click.native="onAnnullaQuotazioneClicked()"
                    />
                </div>

            </div>

        </div>

    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import { mapFields} from "vuex-map-fields";
    import { mapState, mapActions } from "vuex";
    import commonLib from "@/libs/commonLib.js";

    export default {
        name: "AnnullaQuotazione",
        data() {
            return {
                quotazione: {},
                motivo_annullo: ""
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
            ...mapState({
                formPreventivo: state => state.formPreventivo.formPreventivo
            }),
            getStato() {
                if (commonLib.isEmpty(this.quotazione)) return "";

                return this.quotazione.stato.replaceAll("_"," ");
            }
        },
        methods: {
            ...mapActions({
                fetchDatiQuotazione: "gestioneQuotazioni/fetchDatiQuotazione",
                AnnullaQuotazione: "gestioneQuotazioni/AnnullaQuotazione"
            }),
            onIndietroClicked() {
                this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
            },
            onAnnullaQuotazioneClicked() {
                this.$q.dialog({
                        cancel: {
                            focus: true,
                            flat: false,
                            label: "No, neanche un po",
                            color: "primary"
                        },
                        ok: {
                            color: "secondary",
                            flat: false,
                            label: "Si, annulla"
                        },
                        title: 'Attenzione',
                        message: 'Siamo sicuri di voler annullare la quotazione?'
                    }).onOk(async () => {
                        await this.AnnullaQuotazione({
                            guidpreventivo: this.quotazione.guid_preventivo,
                            id_quotazione: this.quotazione.idquotazione,
                            motivo: this.motivo_annullo
                        });

                        this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
                    });
            }
        },
        async mounted() {
            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
            this.motivo_annullo = "";
        },
        async activated() {
            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
            this.motivo_annullo = "";
        }
    }
</script>
